import { createClient } from '@supabase/supabase-js';

import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { MarketplaceInstance } from 'axiosInstances';

// Initialize Firebase
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://localhost:3000'
    : 'https://assetbeyond.com';

const AuthContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [translatedError, setTranslatedError] = useState('');
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [serverUser, setServerUser] = useState(null);

  useEffect(() => {
    switch (errorMessage) {
      case 'A user with this email address has already been registered':
        setTranslatedError(
          'อีเมลนี้มีผู้ใช้แล้ว คุณสามารถรีเซ็ตรหัสผ่านหรือล็อกอินผ่าน Google หรือ Facebook เพื่อดำเนินการต่อ'
        );
        break;
      case 'Email not confirmed':
        setTranslatedError(
          'กรุณาตรวจสอบอีเมลของคุณเพื่อยืนยันบัญชีก่อนเข้าใช้งาน หากคุณไม่ได้รับอีเมลใดๆ กรุณาลงทะเบียนใหม่อีกครั้ง'
        );
        break;
      case 'User not found':
        setTranslatedError('ไม่พบผู้ใช้นี้ในระบบ');
        break;
      case 'Invalid login credentials':
      case 'Invalid email or password':
        setTranslatedError('อีเมลหรือรหัสผ่านไม่ถูกต้อง');
        break;
      case 'For security purposes, you can only request this once every 60 seconds':
        setTranslatedError(
          'เพื่อความปลอดภัย คุณจะสามารถขอรีเซ็ตรหัสผ่านได้ทุกๆ 60 วินาทีเท่านั้น'
        );
        break;
      default:
        setTranslatedError(errorMessage);
        break;
    }
  }, [errorMessage]);

  useEffect(() => {
    if (session) {
      MarketplaceInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${session.access_token}`;
      MarketplaceInstance.get('v1/user').then((response) => {
        setServerUser(response?.data?.data?.attributes);
      });
    }
  }, [session]);

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line no-shadow
    const session = supabase.auth.session();
    setSession(session);
    setUser(session?.user ?? null);

    const { data: authListener } = supabase.auth.onAuthStateChange(
      // eslint-disable-next-line no-shadow
      async (event, session) => {
        setLoading(true);
        setSession(session);
        setUser(session?.user ?? null);
        setLoading(false);
      }
    );
    setLoading(false);

    return () => {
      authListener.unsubscribe();
    };
  }, []);

  const refreshServerUser = () => {
    MarketplaceInstance.get('v1/user').then((response) => {
      setServerUser(response?.data?.data?.attributes);
    });
  };

  const resetMessage = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  const thirdPartyAuth = async (provider) => {
    setLoading(true);
    resetMessage();

    const { error } = await supabase.auth.signIn(
      { provider },
      { redirectTo: `${baseUrl}/login` }
    );

    if (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const changePassword = async (password) => {
    setLoading(true);
    resetMessage();
    setPasswordChangeSuccess(false);

    const { error } = await supabase.auth.update({ password });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage(
        <>
          เปลี่ยนรหัสผ่านเรียบร้อยแล้ว <a href="/login">คลิกที่นี่</a>
          เพื่อเข้าสู่ระบบ
        </>
      );
      setPasswordChangeSuccess(true);
    }
    setLoading(false);
  };

  const sendPasswordResetEmail = async (email) => {
    setLoading(true);
    resetMessage();

    const { error } = await supabase.auth.api.resetPasswordForEmail(email, {
      redirectTo: `${baseUrl}/password_reset_confirmation`,
    });

    console.log({ error });
    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage(
        'เราได้ส่งอีเมลเพื่อรีเซ็ตรหัสผ่านให้คุณแล้ว กรุณาตรวจสอบ Inbox ของคุณและคลิกลิงค์ในอีเมลเพื่อดำเนินการต่อ'
      );
    }
    setLoading(false);
  };

  const signUp = async (email, password) => {
    setLoading(true);
    resetMessage();
    const { error } = await supabase.auth.signUp(
      {
        email,
        password,
      },
      { redirectTo: `${baseUrl}/login` }
    );

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage(
        'สร้างบัญชีเรียบร้อยแล้ว กรุณาตรวจสอบอีเมลของคุณเพื่อยืนยันการเป็นเจ้าของบัญชี'
      );
    }
    setLoading(false);
  };

  const signInWithEmailPassword = async (email, password) => {
    setLoading(true);
    resetMessage();

    const { error } = await supabase.auth.signIn({
      email,
      password,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage('เข้าสู่ระบบเรียบร้อยแล้ว');
    }
    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signOut();

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage('ออกจากระบบแล้ว');
      window.location.href = '/';
    }
    setLoading(false);
  };

  const values = {
    errorMessage,
    successMessage,
    session,
    translatedError,
    user,
    passwordChangeSuccess,
    loading,
    signInWithEmailPassword,
    logout,
    thirdPartyAuth,
    sendPasswordResetEmail,
    changePassword,
    signUp,
    serverUser,
    refreshServerUser,
    resetMessage,
  };

  AuthProvider.propTypes = {
    children: PropTypes.node,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
