import { createStore, combineReducers } from 'redux';
import propertyFilterReducer from 'js/reducers/propertyFilterReducer';
import sellFormReducer from 'js/reducers/sellFormReducer';

const store = createStore(
  combineReducers({ propertyFilterReducer, sellFormReducer }),
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
