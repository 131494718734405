import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const { loading, session } = useAuth();
  return (
    !loading && (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => {
          if (!session?.user) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                // eslint-disable-next-line react/prop-types
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            );
          }

          // authorized so return component
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />;
        }}
      />
    )
  );
}

export default PrivateRoute;
