import { SET_PROPERTY_FILTER } from '../constants/action-types';

const initialState = {
  propertyStatus: [],
  propertyType: [],
  propertyProvince: [],
  propertyAmphoe: [],
  priceRange: [],
};

const propertyFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTY_FILTER:
      return { ...state, [action.filterName]: action.filterValues };
    default:
      return state;
  }
};

export default propertyFilterReducer;
