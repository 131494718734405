import React, { useState } from 'react';
import { Menu as MenuIcon, Close } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  Menu,
  ListItemIcon,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { AssetBeyondLogo } from '../Icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(-2),
  },
  popover: {
    width: 'calc(100% - 16px)',
    padding: theme.spacing(1),
    maxWidth: '100%',
    marginTop: 8,
    left: [8, '!important'],
    right: 0,
  },
}));

const MobileMenu = ({ color, menuList }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.only('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box>
        <IconButton
          disableRipple
          className={classes.menuButton}
          onClick={handleClick}
          size="large"
        >
          <MenuIcon
            fontSize="medium"
            color={!color ? 'action' : 'inherit'}
            style={{ color }}
          />
        </IconButton>
      </Box>
      <Menu
        transitionDuration={250}
        keepMounted
        marginThreshold={0}
        PopoverClasses={{ paper: classes.popover }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box width="100%">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box p={1} ml={1}>
              <AssetBeyondLogo />
            </Box>

            <IconButton onClick={handleClose} size="large">
              <Close fontSize="medium" />
            </IconButton>
          </Box>

          <Divider
            variant="fullWidth"
            style={{ background: 'hsl(0deg 0% 95%)', marginTop: 8 }}
          />
          <List>
            {menuList.map((item) => (
              <ListItem component={Link} button key={item.label} to={item.pathname}>
                <ListItemIcon style={{ minWidth: 48 }}>
                  {React.cloneElement(item.icon, {
                    style: { color: 'hsl(200deg 100% 40%)' },
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    style: { fontWeight: 500 },
                  }}
                />
              </ListItem>
            ))}
          </List>
          <Divider variant="fullWidth" style={{ background: 'hsl(0deg 0% 95%)' }} />
          <Box mx={1} mt={2}>
            <Button
              component={Link}
              to="/login"
              size="large"
              fullWidth={!tablet}
              variant="contained"
              color="primary"
            >
              ลงประกาศฟรี
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

MobileMenu.propTypes = {
  color: PropTypes.string,
  menuList: PropTypes.arrayOf(PropTypes.object),
};

export default MobileMenu;
