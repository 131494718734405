import { AuthProvider } from 'hooks/useAuth';
import { createStore, StateMachineProvider } from 'little-state-machine';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

createStore(
  {
    formStep: 0,
    responseData: {},
    data: {
      uuid: '',
      propertyType: 'house',
      bedroomCount: 0,
      bathroomCount: 0,
      areaSize: '',
      areaSizeUnit: '',
      utilitySpace: '',
      initialAddress: '',
      tambon: null,
      amphoe: '',
      province: '',
      soi: '',
      road: '',
      latitude: '',
      longitude: '',
      propertyStatus: 'sell',
      sellPrice: '',
      rentPrice: '',
      title: '',
      description: '',
      imageValid: false,
      contactName: '',
      contactPhone: '',
      contactLine: '',
      contactEmail: '',
    },
  },
  {
    storageType: null,
  }
);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <StateMachineProvider>
          <App />
        </StateMachineProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
