import Axios from 'axios';

const MarketplaceInstance = Axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://api.marketplace.assetbeyond.com'
      : 'https://api.marketplace.assetbeyond.com',
});

const MainWebInstance = Axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://api.assetbeyond.com'
      : 'https://api.assetbeyond.com',
});

export { MarketplaceInstance, MainWebInstance };
