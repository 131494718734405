import { Tab, Tabs } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

export const AntTabs = withStyles({
  indicator: { display: 'none' },
})(Tabs);

export const AntTabWhiteText = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5, 1.5),
    color: 'hsl(0,0%,90%)',
    fontSize: '0.95rem',
    minWidth: theme.spacing(0),
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(0.5),
    '&:hover': {
      color: 'hsl(0,0%,90%)',
      textDecoration: 'underline',
      opacity: 1,
    },
    '&$selected': {
      color: 'hsl(0,0%,90%)',
    },
    '&:focus': {
      color: 'hsl(0,0%,90%)',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  selected: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

export const AntTab = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5, 1.5),
    color: '#6B7280',
    fontSize: '0.95rem',
    minWidth: theme.spacing(0),
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(0.5),
    '&:hover': {
      color: '#161e2e',
      textDecoration: `solid underline ${theme.palette.primary.main} 3px`,
      opacity: 1,
    },
    '&$selected': {
      color: '#161e2e',
      textDecoration: `solid underline ${theme.palette.primary.main} 3px`,
    },
    '&:focus': {
      color: '#161e2e',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  selected: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);
