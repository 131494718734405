import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider, StyledEngineProvider, Fade } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import 'lazysizes';
import loadable from '@loadable/component';
import Axios from 'axios';
import { Provider } from 'react-redux';
import store from 'js/store/store';

import PrivateRoute from 'PrivateRoute';

import responsiveFontSizesTheme from './mainTheme/mainTheme';

import ScrollToTop from 'shared/ScrollToTop';
import TopNavigation from 'pages/shared/TopNavigation';

const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy'));
const TermsOfService = loadable(() => import('./pages/TermsOfService'));
const Home = loadable(() => import('./pages/Home'), {
  fallback: <TopNavigation />,
});
const LandingPage = loadable(() => import('./pages/LandingPage'), {
  fallback: <TopNavigation />,
});
const PropertySearch = loadable(() => import('./pages/PropertySearch'), {
  fallback: <TopNavigation />,
});
const PropertyShow = loadable(() => import('./pages/PropertyShow'), {
  fallback: <TopNavigation />,
});
const PropertySellForm = loadable(() => import('./pages/PropertySellForm'));
const ContactUs = loadable(() => import('./pages/ContactUs'));
const Marketing = loadable(() => import('./pages/Marketing'));
const NotFound = loadable(() => import('./pages/NotFound'));
const Authentication = loadable(() => import('./pages/Authentication'));
const PublicPropertyPost = loadable(() => import('./pages/PublicPropertyPost'));
const UserDashboard = loadable(() => import('./pages/UserDashboard'));
const PropertyShowForMembers = loadable(
  () => import('./pages/PropertyShow/PropertyShowForMembers'),
  {
    fallback: <TopNavigation />,
  }
);

Axios.defaults.baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://api.assetbeyond.com';

Axios.defaults.baseURL = 'https://api.assetbeyond.com';

function App() {
  return (
    <>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={responsiveFontSizesTheme}>
              <SnackbarProvider TransitionComponent={Fade} maxSnack={1}>
                <Switch>
                  <Route path="/" component={Home} exact />
                  <Route path="/sell-with-us" component={LandingPage} exact />
                  <Route path="/sell-land-with-us" component={LandingPage} exact />
                  <Route path="/marketing" component={Marketing} exact />
                  <Route path="/sell-form" component={PropertySellForm} exact />
                  <Route path="/sell-land-form" component={PropertySellForm} exact />
                  <Route path="/property-search" component={PropertySearch} exact />
                  <Route path="/property/:encodedUUID" component={PropertyShow} />
                  <Route
                    path="/properties/:encodedUUID"
                    component={PropertyShowForMembers}
                  />
                  <Route path="/property" component={PropertyShow} />
                  <Route path="/contact-us" component={ContactUs} exact />
                  <Route path="/login" component={Authentication} exact />
                  <Route path="/register" component={Authentication} exact />
                  <Route path="/password_reset" component={Authentication} exact />
                  <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                  <Route path="/terms-of-service" component={TermsOfService} exact />
                  <PrivateRoute path="/dashboard" component={UserDashboard} />
                  <Route
                    path="/password_reset_confirmation"
                    component={Authentication}
                    exact
                  />

                  <PrivateRoute
                    path="/post-property"
                    exact
                    component={PublicPropertyPost}
                  />
                  <Route component={NotFound} />
                </Switch>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </Provider>
    </>
  );
}

export default App;
