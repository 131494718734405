import React from 'react';
import { AppBar, Toolbar, Box, Hidden, Avatar, Button, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MobileMenu from '../MobileMenu/MobileMenu';
import { AntTabs, AntTab } from './AntTab';
import { AssetBeyondLogo } from '../Icons';
import { AntTabWhiteText } from './AntTab/AntTabs';
import { Link as RouterLink } from 'react-router-dom';
import { CallOutlined, HomeOutlined, SearchOutlined } from '@mui/icons-material';
import { mdiBullhornOutline } from '@mdi/js';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.only('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  fullWidthToolbar: {
    [theme.breakpoints.between('sm', 'xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  logo: {
    marginRight: theme.spacing(1.5),
    background: 'none',
  },
}));

const menuList = [
  {
    label: 'หน้าหลัก',
    pathname: '/',
    icon: <HomeOutlined></HomeOutlined>,
  },
  {
    label: 'ค้นหาทรัพย์',
    pathname: '/property-search',
    icon: <SearchOutlined></SearchOutlined>,
  },
  {
    label: 'ฝากขายทรัพย์',
    pathname: '/sell-with-us',
    icon: (
      <SvgIcon>
        <path d={mdiBullhornOutline}></path>
      </SvgIcon>
    ),
  },
  {
    label: 'ติดต่อเรา',
    pathname: '/contact-us',
    icon: <CallOutlined />,
  },
];

const pathNames = menuList.map((button) => button.pathname);

const TopNavigation = ({
  variant = 'default',
  fullWidth = false,
  centerElement = null,
  position,
  withSellButton,
  lightText = false,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isTransparent = variant === 'transparent';

  return (
    <AppBar
      elevation={isTransparent ? 0 : 1}
      style={{
        background: variant === 'default' ? 'white' : 'transparent',
        zIndex: 9,
      }}
      position={position || 'relative'}
    >
      <Box width="100%" maxWidth={fullWidth ? 1920 : { lg: 1200, xl: 1280 }} mx="auto">
        <Toolbar className={clsx(classes.toolbar, fullWidth && classes.fullWidthToolbar)}>
          <Box mr={{ lg: 'auto' }} flex={{ lg: 1 }}>
            <Avatar component={RouterLink} to="/" className={classes.logo}>
              <AssetBeyondLogo white={lightText} />
            </Avatar>
          </Box>

          {(
            <Box justifyContent="center" flex={{ lg: 1 }} flexGrow={{ xs: 1, lg: 0 }}>
              {centerElement}
            </Box>
          ) || <Box flexGrow={1} />}

          <Hidden mdUp>
            <Box
              flexGrow={{ xs: null, lg: 1 }}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {withSellButton && (
                <Button
                  component={RouterLink}
                  to={'/sell-with-us'}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  ฝากขายทรัพย์
                </Button>
              )}

              <MobileMenu
                color={lightText ? 'hsl(0,0%,90%)' : null}
                menuList={menuList}
              />
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box
              ml={{ lg: 'auto' }}
              flex={centerElement ? { lg: 1 } : null}
              display="flex"
              justifyContent="flex-end"
            >
              <AntTabs
                indicatorColor="primary"
                textColor="primary"
                value={pathNames.includes(location.pathname) && location.pathname}
              >
                {menuList.map((menu) =>
                  lightText ? (
                    <AntTabWhiteText
                      key={menu.label}
                      value={menu.pathname}
                      label={menu.label}
                      component={RouterLink}
                      to={menu.pathname}
                    />
                  ) : (
                    <AntTab
                      key={menu.label}
                      value={menu.pathname}
                      label={menu.label}
                      component={RouterLink}
                      to={menu.pathname}
                    />
                  )
                )}
                <Box ml={1} display="flex" alignItems="center">
                  <Button
                    component={RouterLink}
                    size="small"
                    to="/login"
                    disableElevation
                    variant="outlined"
                    color="primary"
                  >
                    ลงประกาศฟรี
                  </Button>
                </Box>
              </AntTabs>
            </Box>
          </Hidden>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

TopNavigation.defaultProps = {
  variant: 'default',
  fullWidth: false,
};

TopNavigation.propTypes = {
  variant: PropTypes.oneOf(['default', 'transparent']),
  fullWidth: PropTypes.bool,
  centerElement: PropTypes.node,
  position: PropTypes.string,
  withSellButton: PropTypes.bool,
  lightText: PropTypes.bool,
};

export default TopNavigation;
