import { teal } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h1: {
      fontSize: '4.8rem',
    },
    subtitle2: {
      fontSize: '0.9rem',
    },
    fontSize: 14,
    fontFamily: `${['Noto Sans Thai UI', 'Roboto', 'Montserrat', 'sans-serif'].join(
      ','
    )}!important`,
  },
  palette: {
    accent: {
      light: teal[500],
      dark: 'hsl(243, 100%, 67%)',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#484848',
      secondary: '#727272',
    },
    shades: {
      yellow: {
        main: 'rgb(244, 197, 66)',
      },
      grey: {
        100: 'hsl(203, 15%, 95%)',
        200: 'hsl(203, 15%, 60%)',
        300: 'hsl(203,15%,47%)',
        400: 'hsl(201,23%,34%)',
      },
      blue: {
        light: '#4D93D5',
        medium: '#0C6FCC',
        dark: '#095399',
        darker: '#1C354D',
      },
    },
    primary: {
      main: 'hsl(204, 75%, 47%)',
    },
    secondary: {
      main: '#bbd7eb',
    },
  },
});

theme.shadows[1] =
  '0 4px 8px hsla(0,0%,0%,0.04), 0 0 2px hsla(0,0%,0%,0.06), 0 0 1px hsla(0,0%,0%,0.04)';
theme.shadows[2] = '0 4px 6px hsla(0,0%,0%,0.1)';
theme.shadows[3] = '0 4px 15px hsla(0,0%,0%,0.1)';
theme.shadows[4] =
  '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)';
theme.shadows[5] = '0 15px 35px hsla(0,0%,0%,0.1)';

const responsiveFontSizesTheme = responsiveFontSizes(theme);

export default responsiveFontSizesTheme;
