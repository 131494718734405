import { SET_SELECTED_PROVINCE } from '../constants/action-types';

const initialState = {
  sellingProvince: 'bangkok',
};

const sellFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROVINCE:
      return { ...state, sellingProvince: action.payload };
    default:
      return state;
  }
};

export default sellFormReducer;
